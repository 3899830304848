import React from 'react';
import { useNavigate } from "react-router-dom";

const Header = () => {
    const navigate = useNavigate();

    const handleDocsClick = () => {
        window.open('https://docs.lobbyfi.xyz/lobbyfi-pills-system', '_blank');
    };

    return (
        <div className="text-xs sm:text-base w-full bg-[#96fdbf] font-header text-black text-center py-1 my-3" style={{ fontFamily: "var(--body-text-font-family)" }}>
            💊 Earn Red Pills (for delegation) & Blue Pills (for acquisition)! {'   '}
            <button 
                onClick={handleDocsClick}
                className="underline hover:text-green-800 cursor-pointer"
            >
                [Learn more about Pills System] 💊
            </button>
        </div>
    );
};

export default Header;