import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import Navbar from "./components/Navbar";
import InfoBar from "./components/InfoLine";
import { ReactComponent as RedPillIcon } from "./assets/red_pill.svg";
import { ReactComponent as BluePillIcon } from "./assets/blue_pill.svg";
import { useAccount } from "wagmi"; // Changed to useAccount hook
import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  useQuery,
  gql,
  createHttpLink,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import VectorGrid from "./assets/vector_grid.svg";

const httpLink = createHttpLink({
  uri: "https://lobbyfi.xyz/graphql", //http://localhost:4000/graphql https://lobbyfi.xyz/graphql
});

const authLink = setContext((_, { headers }) => {
  const apiKey = process.env.REACT_APP_GRAPHQL_API_KEY;
  return {
    headers: {
      ...headers,
      "x-api-key": apiKey || "",
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

export const GET_POINTS_STATS = gql`
  query GetPointsStats {
    pointsStats {
      total_blue_points
      total_red_points
      points_minted_this_week {
        blue
        red
      }
      active_wallets_count
      total_proposals_with_points
    }
  }
`;

// Separate queries for leaderboard and user points
const GET_LEADERBOARD = gql`
  query GetLeaderboard($pointsType: String!, $limit: Int, $offset: Int) {
    pointsLeaderboard(pointsType: $pointsType, limit: $limit, offset: $offset) {
      wallet
      points
      rank
    }
  }
`;

const GET_USER_POINTS = gql`
  query GetUserPoints($wallet: String!) {
    userPoints(wallet: $wallet) {
      blue_points
      red_points
    }
  }
`;

const PointsCard = ({ title, value, icon: Icon, tooltipText, isRed }) => {
  const isSplitView =
    value && typeof value === "object" && "blue" in value && "red" in value;

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className={`bg-[#343434] border-[#96fdbf] border rounded-lg p-6 shadow-md hover:shadow-lg 
                  transition-all duration-300 relative group ${
                    isRed
                      ? "hover:shadow-red-500/20"
                      : "hover:shadow-blue-500/20"
                  }`}
    >
      <div
        className={`absolute inset-0 ${
          isRed ? "bg-red-500/5" : "bg-blue-500/5"
        } opacity-0 group-hover:opacity-100 transition-opacity duration-300 rounded-lg`}
      />

      <div className="flex flex-col h-full">
        <div className="flex items-center justify-between mb-4">
          {/* Left icon */}
          <div className="transform transition-transform duration-300 group-hover:scale-110 w-8">
            {typeof Icon === "string" ? (
              <span className="text-2xl">{Icon}</span>
            ) : (
              <Icon className="w-8 h-8" />
            )}
          </div>

          {/* Center title */}
          <h3 className="text-base text-[#96fdbf] font-semibold text-center flex-1 mx-4 whitespace-nowrap">
            {title}
          </h3>

          {/* Right help icon */}
          <div className="w-8">
            <div className="group/tooltip relative cursor-help text-gray-400 hover:text-[#7fdba7] transition-colors duration-200">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                  clipRule="evenodd"
                />
              </svg>
              <span
                className="invisible group-hover/tooltip:visible opacity-0 group-hover/tooltip:opacity-100 
                          transition-all duration-200 absolute left-1/2 -translate-x-1/2 -translate-x-full
                          top-full mt-2 w-72 p-4 bg-black border border-[#96fdbf] rounded-lg 
                          text-sm text-[#96fdbf] shadow-xl z-50"
              >
                {tooltipText}
              </span>
            </div>
          </div>
        </div>

        {isSplitView ? (
          <div className="space-y-4">
            <div className="flex justify-around">
              <div className="text-center">
                <p className="text-sm text-red-400">Red Pills</p>
                <motion.p
                  className="text-2xl font-bold text-red-500"
                  initial={{ scale: 0.5, opacity: 0 }}
                  animate={{ scale: 1, opacity: 1 }}
                  transition={{ duration: 0.5, delay: 0.2 }}
                >
                  {Number(value.red || 0).toLocaleString()}
                </motion.p>
              </div>
              <div className="text-center">
                <p className="text-sm text-blue-400">Blue Pills</p>
                <motion.p
                  className="text-2xl font-bold text-blue-500"
                  initial={{ scale: 0.5, opacity: 0 }}
                  animate={{ scale: 1, opacity: 1 }}
                  transition={{ duration: 0.5, delay: 0.2 }}
                >
                  {Number(value.blue || 0).toLocaleString()}
                </motion.p>
              </div>
            </div>
          </div>
        ) : (
          <div className="text-center">
            <motion.p
              className="text-4xl font-bold text-[#96fdbf] tracking-wider"
              initial={{ scale: 0.5, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              transition={{ duration: 0.5, delay: 0.2 }}
            >
              {Number(value || 0).toLocaleString()}
            </motion.p>
          </div>
        )}
      </div>
    </motion.div>
  );
};

const TabButton = ({ active, onClick, children, type }) => (
  <button
    onClick={onClick}
    className={`px-6 py-3 rounded-lg font-medium transition-all duration-200 
      ${
        active
          ? `${
              type === "blue_points" ? "bg-blue-500/20" : "bg-red-500/20"
            } text-[#96fdbf]`
          : "bg-transparent text-gray-400 hover:text-[#96fdbf]"
      }`}
  >
    {children}
  </button>
);

const UserPositionCard = ({ userPosition, selectedType }) => {
  if (!userPosition) return null;
};

const LeaderboardTable = ({
  data,
  loading,
  userWallet,
  selectedType,
  onLoadMore,
  hasMore,
}) => {
  if (loading && !data.length) {
    return <div className="text-center py-8 text-[#96fdbf]">Loading...</div>;
  }

  const getUserPosition = () => {
    if (!userWallet) return null;
    const userInLeaderboard = data.find(
      (entry) => entry.wallet.toLowerCase() === userWallet.toLowerCase()
    );
    if (userInLeaderboard) return userInLeaderboard;
    return {
      wallet: userWallet,
      points: 0,
      rank: data.length + 1,
    };
  };

  const userPosition = getUserPosition();
  const otherEntries = data.filter(
    (entry) => entry.wallet.toLowerCase() !== userWallet?.toLowerCase()
  );

  return (
    <div>
      <div className="overflow-x-auto">
        <table className="w-full">
          <thead>
            <tr>
              <th className="text-left py-4 px-6 text-[#96fdbf] font-medium text-lg border-b border-[#96fdbf]/20">
                Rank
              </th>
              <th className="text-left py-4 px-6 text-[#96fdbf] font-medium text-lg border-b border-[#96fdbf]/20">
                Wallet
              </th>
              <th className="text-right py-4 px-6 text-[#96fdbf] font-medium text-lg border-b border-[#96fdbf]/20">
                Pills
              </th>
            </tr>
          </thead>
          <tbody>
            {userPosition && (
              <tr className="bg-gray-800 transition-colors duration-200 sticky top-0">
                <td className="py-4 px-6 text-[#96fdbf]">
                  #{userPosition.rank}
                </td>
                <td className="py-4 px-6 text-[#96fdbf]">
                  {shortenAddress(userPosition.wallet)}
                  {<span className="ml-2 text-sm text-[#96fdbf]/70">You</span>}
                </td>
                <td className="py-4 px-6 text-[#96fdbf] text-right">
                  {Number(userPosition.points).toLocaleString()}
                </td>
              </tr>
            )}
            {otherEntries.map((entry) => (
              <tr
                key={entry.wallet}
                className="hover:bg-gray-800 transition-colors duration-200"
              >
                <td className="py-4 px-6 text-[#96fdbf]">#{entry.rank}</td>
                <td className="py-4 px-6 text-[#96fdbf]">
                  {shortenAddress(entry.wallet)}
                </td>
                <td className="py-4 px-6 text-[#96fdbf] text-right">
                  {Number(entry.points).toLocaleString()}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {hasMore && (
        <div className="mt-6 text-center">
          <button
            onClick={onLoadMore}
            disabled={loading}
            className={`px-6 py-3 rounded-lg font-medium transition-all duration-200 
                ${
                  loading
                    ? "bg-gray-700 text-gray-400 cursor-not-allowed"
                    : "bg-[#96fdbf]/10 text-[#96fdbf] hover:bg-[#96fdbf]/20"
                }`}
          >
            {loading ? (
              <div className="flex items-center space-x-2">
                <span>Loading...</span>
                <div className="w-4 h-4 border-2 border-[#96fdbf] border-t-transparent rounded-full animate-spin"></div>
              </div>
            ) : (
              "Load More"
            )}
          </button>
        </div>
      )}
    </div>
  );
};

const shortenAddress = (address) => {
  if (!address) return "";
  return `${address.slice(0, 6)}...${address.slice(-4)}`;
};

const LeaderboardPage = () => {
  const [selectedType, setSelectedType] = useState("red_points");
  const { address: userWallet, isConnecting, isConnected } = useAccount();

  const { loading: statsLoading, data: statsData } = useQuery(
    GET_POINTS_STATS,
    {
      client,
    }
  );

  const [offset, setOffset] = useState(0);
  const limit = 50;

  const {
    loading: leaderboardLoading,
    data: leaderboardData,
    fetchMore,
  } = useQuery(GET_LEADERBOARD, {
    variables: {
      pointsType: selectedType,
      limit,
      offset,
    },
    client,
  });

  const { data: userPointsData } = useQuery(GET_USER_POINTS, {
    variables: {
      wallet: userWallet || "",
    },
    client,
    skip: !userWallet,
  });

  const stats = statsData?.pointsStats || {};
  const leaderboard = leaderboardData?.pointsLeaderboard || [];
  const userPoints = userPointsData?.userPoints;

  const getUserPosition = () => {
    if (!userWallet || !isConnected) return null;

    // Find user in leaderboard if they exist
    const userInLeaderboard = leaderboard.find(
      (entry) => entry.wallet.toLowerCase() === userWallet.toLowerCase()
    );

    // If user is found in leaderboard, return their position
    if (userInLeaderboard) {
      return userInLeaderboard;
    }

    // If user is not in leaderboard, return a default position with 0 points
    // and a rank after all existing entries
    return {
      wallet: userWallet,
      points: 0,
      rank: leaderboard.length + 1,
    };
  };

  const handleLoadMore = () => {
    const newOffset = offset + limit;
    setOffset(newOffset);

    fetchMore({
      variables: {
        offset: newOffset,
        limit,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return {
          ...prev,
          pointsLeaderboard: [
            ...prev.pointsLeaderboard,
            ...fetchMoreResult.pointsLeaderboard,
          ],
        };
      },
    });
  };

  return (
    <div
      className="bg-black text-[#96fdbf] min-h-screen flex flex-col justify-between"
      style={{ fontFamily: "var(--body-text-font-family)" }}
    >
      <div className="flex flex-col items-center justify-between w-full">
        <Navbar />
        <InfoBar />
      </div>

      <div className="container mx-auto px-4 py-8 bg-black">
        {/* KPIs Section */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-12">
          <PointsCard
            title={
                <div className="text-center">
                  Total Red Pills
                  <div className="text-xs text-red-400/80">(for delegation)</div>
                </div>
              }
            value={stats.total_red_points}
            icon={RedPillIcon}
            tooltipText="Red Pills are distributed for the delegation based on the proposal's bids / instant buy price. For each 0.001 ETH spent, one red pill is minted. The pills are then distributed between the delegators based on the $$$ they delegated."
            isRed
          />
          <PointsCard
            title={
                <div className="text-center">
                  Total Blue Pills
                  <div className="text-xs text-blue-400/80">(for acquisition)</div>
                </div>
              }
            value={stats.total_blue_points}
            icon={BluePillIcon}
            tooltipText="Blue Pills are distributed for the voting power acquiring based on the proposal's bids / instant buy price. For each 0.001 ETH spent, the bidder / buyer gets one blue pill."
          />
          <PointsCard
            title="Weekly Minted"
            value={stats.points_minted_this_week}
            icon="📅"
            tooltipText="Total pills minted in the last 7 days across all categories."
          />
          <PointsCard
            title="Active Wallets"
            value={stats.active_wallets_count}
            icon="👔"
            tooltipText="Number of unique wallets that have earned pills."
          />
        </div>

        {/* Type Selector */}
        <div className="flex justify-center space-x-4 mb-8">
          <TabButton
            active={selectedType === "red_points"}
            onClick={() => setSelectedType("red_points")}
            type="red_points"
          >
            Red Pills
          </TabButton>
          <TabButton
            active={selectedType === "blue_points"}
            onClick={() => setSelectedType("blue_points")}
            type="blue_points"
          >
            Blue Pills
          </TabButton>
        </div>

        {/* Leaderboard Section */}
        <div className="bg-[#343434] border-[#96fdbf] border rounded-lg p-6">
          {isConnected && userWallet && (
            <UserPositionCard
              userPosition={getUserPosition()}
              selectedType={selectedType}
            />
          )}
          <LeaderboardTable
            data={leaderboard}
            loading={leaderboardLoading}
            userWallet={userWallet}
            selectedType={selectedType}
            onLoadMore={handleLoadMore}
            hasMore={leaderboard.length % limit === 0 && leaderboard.length > 0}
          />
        </div>
      </div>

      {/* Footer */}
      <div className="flex flex-col items-end justify-center w-full mt-12">
        <div className="flex justify-between items-center w-full px-[2px] py-2 bg-black">
          <p className="text-xs sm:text-base font-body-text text-[#96fdbf] tracking-[1.00px] leading-[20px]">
            © 2025 LobbyFi. All rights reserved.
          </p>
        </div>
        <div className="w-full bg-black">
          <img src={VectorGrid} alt="Retro Vector Grid" className="w-full" />
        </div>
      </div>
    </div>
  );
};

export default LeaderboardPage;
