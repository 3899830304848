import React, { useState } from "react";

import LobbyFiLogo from "../assets/LobbyFiLogo.png";
import WalletButton from "./WalletButton";
import WalletConnectButton from "./WalletConnectButton";
import "../tailwind.css";
import { useNavigate } from "react-router-dom";

import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import { FaTelegram } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

const Navbar = () => {
  const [nav, setNav] = useState(false);
  const navigate = useNavigate();

  const handleNav = () => {
    setNav(!nav);
  };

  const handleButtonClick = () => {
    navigate("/MyRewards");
  };

  const handleLeaderBoardClick = () => {
    navigate("/LeaderBoard");
  };

  const handleDocsClick = () => {
    window.open("https://docs.lobbyfi.xyz/", "_blank");
  };

  const handleLogoClick = () => {
    navigate("/");
  };

  const ButtonWrapper = ({ onClick, children }) => (
    <button
      onClick={onClick}
      className="flex items-center w-full px-4 py-3 text-[#96fdbf] hover:text-[#3DFF54] transition-all group"
      style={{ fontFamily: "var(--body-text-font-family)" }}
    >
      <div className="flex items-center justify-center w-full bg-[#373737] hover:bg-gray-800 px-4 py-2.5 rounded transition-all">
        <span className="text-base">{children}</span>
      </div>
    </button>
  );

  return (
    <div className="w-full h-24">
      <div className="max-w-[1920px] h-full mx-auto px-6 md:px-8 flex justify-between items-center">
        {/* Logo Section - Always flush left */}
        <div onClick={handleLogoClick} className="cursor-pointer">
          <img
            className="h-28 object-contain"
            alt="lobbyfi logo"
            src={LobbyFiLogo}
          />
        </div>

        {/* Desktop Navigation - Flush right */}
        <div className="hidden md:flex items-center space-x-8 pr-2 mt-4">
          <div className="flex items-center space-x-6">
            <a
              href="https://t.me/lobbyfichat"
              target="_blank"
              rel="noopener noreferrer"
              className="text-[#96fdbf] hover:text-[#3DFF54] transition-colors flex items-center"
            >
              [<FaTelegram size={14} className="mx-0.5" />]
            </a>
            <a
              href="https://twitter.com/lobbyfinance"
              target="_blank"
              rel="noopener noreferrer"
              className="text-[#96fdbf] hover:text-[#3DFF54] transition-colors flex items-center"
            >
              [<FaXTwitter size={14} className="mx-0.5" />]
            </a>
          </div>
          <button
            onClick={handleDocsClick}
            className="font-header text-[#96fdbf] whitespace-nowrap hover:text-[#3DFF54] transition-colors text-lg"
            style={{ fontFamily: "var(--body-text-font-family)" }}
          >
            [docs]
          </button>
          <button
            onClick={handleLeaderBoardClick}
            className="font-header text-[#96fdbf] whitespace-nowrap hover:text-[#3DFF54] transition-colors text-lg"
            style={{ fontFamily: "var(--body-text-font-family)" }}
          >
            [leaderboard]
          </button>
          <button
            onClick={handleButtonClick}
            className="font-header text-[#96fdbf] whitespace-nowrap hover:text-[#3DFF54] transition-colors text-lg"
            style={{ fontFamily: "var(--body-text-font-family)" }}
          >
            [my rewards]
          </button>
          <WalletButton />
        </div>

        {/* Mobile Menu Button */}
        <div onClick={handleNav} className="md:hidden cursor-pointer">
          {nav ? (
            <AiOutlineClose size={20} className="text-[#96fdbf]" />
          ) : (
            <AiOutlineMenu size={20} className="text-[#96fdbf]" />
          )}
        </div>

        {/* Mobile Menu */}
        {nav && (
          <div className="fixed inset-0 bg-black bg-opacity-50 z-40 md:hidden">
            <div className="fixed top-0 left-0 right-0 bg-black border-b border-[#96fdbf] rounded-b-lg shadow-lg">
              {/* Menu Title */}
              <div className="flex justify-center">
                <span
                  className="text-[#96fdbf] text-lg font-header"
                  style={{ fontFamily: "var(--body-text-font-family)" }}
                >
                  Menu
                </span>
              </div>

              {/* Menu Items */}
              <div className="p-2 space-y-2">
                <ButtonWrapper onClick={handleDocsClick}>[docs]</ButtonWrapper>

                <ButtonWrapper onClick={handleLeaderBoardClick}>
                  [leaderboard]
                </ButtonWrapper>

                <ButtonWrapper onClick={handleButtonClick}>
                  [my rewards]
                </ButtonWrapper>

                <div className="p-2 -mx-2">
                  <WalletConnectButton />
                </div>
              </div>
              {/* Social Links */}
              <div className="flex items-center justify-center space-x-8 py-3">
                <a
                  href="https://t.me/lobbyfichat"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center justify-center w-32 px-4 py-2.5 text-[#96fdbf] hover:text-[#3DFF54] bg-[#96fdbf]/5 hover:bg-gray-800 rounded transition-all"
                >
                  <FaTelegram size={20} className="mr-2" />
                  <span className="text-base">Telegram</span>
                </a>
                <a
                  href="https://twitter.com/lobbyfinance"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center justify-center w-32 px-4 py-2.5 text-[#96fdbf] hover:text-[#3DFF54] bg-[#96fdbf]/5 hover:bg-gray-800 rounded transition-all"
                >
                  <FaXTwitter size={20} className="mr-2" />
                  <span className="text-base">Twitter</span>
                </a>
              </div>
              {/* Cancel Button */}
              <div className="p-2 pb-4">
                <ButtonWrapper onClick={() => setNav(false)}>
                  Close Menu
                </ButtonWrapper>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
