import React, { useState, useEffect } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Area,
  AreaChart,
} from "recharts";
import { Loader2 } from "lucide-react";
import { motion } from "framer-motion";
import { ReactComponent as RedPillIcon } from "../assets/red_pill.svg";
import { ReactComponent as BluePillIcon } from "../assets/blue_pill.svg";
import { gql, useQuery } from "@apollo/client";

const USER_POINTS_QUERY = gql`
  query UserPoints($wallet: String!) {
    userPoints(wallet: $wallet) {
      blue_points
      red_points
      last_updated
    }
    pointsHistory(wallet: $wallet, limit: 1000) {
      points_type
      points_change
      timestamp
    }
  }
`;

const PointsCounter = ({ value, duration = 2 }) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    const end = parseInt(value) || 0;

    if (end === 0) {
      setCount(0);
      return;
    }

    let start = 0;
    const incrementTime = (duration * 1000) / end;
    const step = Math.max(1, Math.floor(end / 100));

    const timer = setInterval(() => {
      start = Math.min(start + step, end);
      setCount(start);
      if (start === end) clearInterval(timer);
    }, incrementTime);

    return () => clearInterval(timer);
  }, [value, duration]);

  return <span>{count.toLocaleString()}</span>;
};

const PointsCard = ({ title, value, icon: Icon, tooltipText, isRed }) => (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className={`bg-[#343434] border-[#96fdbf] border rounded-lg p-6 shadow-md hover:shadow-lg 
                  transition-all duration-300 relative group ${
                    isRed ? "hover:shadow-red-500/20" : "hover:shadow-blue-500/20"
                  }`}
    >
      <div
        className={`absolute inset-0 ${
          isRed ? "bg-red-500/5" : "bg-blue-500/5"
        } opacity-0 group-hover:opacity-100 
                      transition-opacity duration-300 rounded-lg`}
      />
      <div className="flex flex-col h-full">
        <div className="flex items-center justify-between mb-4">
          <div className="w-16 transform transition-transform duration-300 group-hover:scale-110">
            <Icon className="w-12 h-6 md:w-14 md:h-7 lg:w-16 lg:h-8" />
          </div>
          <h3 className="text-xl text-[#96fdbf] font-bold px-4">{title}</h3>
          <div className="w-16 flex justify-end">
            <div className="group/tooltip relative cursor-help text-gray-400 hover:text-[#7fdba7] transition-colors duration-200">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                  clipRule="evenodd"
                />
              </svg>
              <span
                className="invisible group-hover/tooltip:visible opacity-0 group-hover/tooltip:opacity-100 
                           transition-all duration-200 absolute left-1/2 -translate-x-1/2 -translate-x-full 
                           top-full mt-2 w-72 p-4 bg-black border border-[#96fdbf] rounded-lg 
                           text-sm text-[#96fdbf] shadow-xl z-50"
              >
                {tooltipText}
              </span>
            </div>
          </div>
        </div>
        <div className="text-center">
          <motion.p
            className="text-4xl font-bold text-[#96fdbf] tracking-wider"
            initial={{ scale: 0.5, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            <PointsCounter value={value || 0} />
          </motion.p>
        </div>
      </div>
    </motion.div>
  );

const PointsDisplay = ({ wallet, client }) => {
  const { loading, error, data } = useQuery(USER_POINTS_QUERY, {
    variables: { wallet: wallet?.toLowerCase() },
    skip: !wallet,
  });

  // Process timeline data with accumulative values
  const processTimelineData = (historyData) => {
    if (!historyData) return [];

    // First, sort the history data by timestamp
    const sortedHistory = [...historyData].sort(
      (a, b) => parseInt(a.timestamp) - parseInt(b.timestamp)
    );

    let blueTotal = 0;
    let redTotal = 0;

    // Create processed data with running totals
    const processedData = sortedHistory.reduce((acc, point) => {
      const date = new Date(parseInt(point.timestamp) * 1000);
      const dateStr = date.toLocaleDateString();

      // Update running totals
      if (point.points_type === "blue_points") {
        blueTotal += point.points_change;
      } else if (point.points_type === "red_points") {
        redTotal += point.points_change;
      }

      // Find existing entry or create new one
      const existingEntry = acc.find((entry) => entry.date === dateStr);
      if (existingEntry) {
        existingEntry.accBlue = blueTotal;
        existingEntry.accRed = redTotal;
      } else {
        acc.push({
          date: dateStr,
          timestamp: point.timestamp,
          accBlue: blueTotal,
          accRed: redTotal,
        });
      }

      return acc;
    }, []);

    return processedData;
  };

  const chartData = processTimelineData(data?.pointsHistory);

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="bg-black/90 border border-[#96fdbf] rounded-lg p-4 shadow-lg">
          <p className="text-[#96fdbf] font-medium mb-2">{label}</p>
          {payload.map((entry) => (
            <p
              key={entry.dataKey}
              className="text-sm"
              style={{
                color: entry.dataKey === "accBlue" ? "#3b82f6" : "#ef4444",
              }}
            >
              {entry.dataKey === "accBlue"
                ? "Total Blue Pills: "
                : "Total Red Pills: "}
              {entry.value.toLocaleString()}
            </p>
          ))}
        </div>
      );
    }
    return null;
  };

  if (!wallet) {
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="text-center text-[#96fdbf] py-8 text-lg"
      >
        Connect your wallet to see your points
      </motion.div>
    );
  }

  if (loading) {
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="flex justify-center items-center py-12"
      >
        <Loader2 className="h-12 w-12 animate-spin text-[#96fdbf]" />
      </motion.div>
    );
  }

  if (error) {
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="text-red-500 text-center py-8 bg-red-500/10 rounded-lg border border-red-500"
      >
        Error loading points data
      </motion.div>
    );
  }

  return (
    <div className="w-full space-y-6">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <PointsCard
           title={
            <div className="text-center">
              Red Pills
              <div className="text-xs text-red-400/80">(for delegation)</div>
            </div>
          }
          value={data?.userPoints?.red_points}
          icon={RedPillIcon}
          tooltipText="Red Pills are distributed for the delegation based on the proposal's bids / instant buy price. For each 0.001 ETH spent, one red pill is minted. The pills are then distributed between the delegators based on the $$ they delegated."
          isRed={true}
        />
        <PointsCard
          title={
            <div className="text-center">
              Blue Pills
              <div className="text-xs text-blue-400/80">(for acquisition)</div>
            </div>
          }
          value={data?.userPoints?.blue_points}
          icon={BluePillIcon}
          tooltipText="Blue Pills are distributed for the voting power acquiring based on the proposal's bids / instant buy price. For each 0.001 ETH spent, the bidder / buyer gets one blue pill."
          isRed={false}
        />
      </div>

      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.3 }}
        className="bg-[#343434] border-[#96fdbf] border rounded-lg p-6 shadow-md hidden md:block"
      >
        <h3 className="text-xl text-[#96fdbf] font-bold mb-6">Pills History</h3>
        <div className="h-72 overflow-x-auto">
          <div
            style={{
              minWidth: `${Math.max(800, chartData.length * 80)}px`,
              paddingRight: "30px",
            }}
            className="h-full pb-4"
          >
            {chartData.length > 0 ? (
              <ResponsiveContainer width="100%" height="100%">
                <AreaChart
                  data={chartData}
                  margin={{ top: 10, right: 50, left: 0, bottom: 20 }}
                >
                  <defs>
                    <linearGradient
                      id="blueGradient"
                      x1="0"
                      y1="0"
                      x2="0"
                      y2="1"
                    >
                      <stop offset="5%" stopColor="#3b82f6" stopOpacity={0.2} />
                      <stop offset="95%" stopColor="#3b82f6" stopOpacity={0} />
                    </linearGradient>
                    <linearGradient
                      id="redGradient"
                      x1="0"
                      y1="0"
                      x2="0"
                      y2="1"
                    >
                      <stop offset="5%" stopColor="#ef4444" stopOpacity={0.2} />
                      <stop offset="95%" stopColor="#ef4444" stopOpacity={0} />
                    </linearGradient>
                  </defs>
                  <XAxis
                    dataKey="date"
                    stroke="#96fdbf"
                    tick={{ fill: "#96fdbf" }}
                    interval={Math.floor(chartData.length / 10)} // Show fewer x-axis labels
                  />
                  <YAxis stroke="#96fdbf" tick={{ fill: "#96fdbf" }} />
                  <Tooltip content={<CustomTooltip />} />
                  <Area
                    type="monotone"
                    dataKey="accBlue"
                    name="Blue Pills"
                    stroke="#3b82f6"
                    fillOpacity={1}
                    fill="url(#blueGradient)"
                    strokeWidth={2}
                  />
                  <Area
                    type="monotone"
                    dataKey="accRed"
                    name="Red Pills"
                    stroke="#ef4444"
                    fillOpacity={1}
                    fill="url(#redGradient)"
                    strokeWidth={2}
                  />
                </AreaChart>
              </ResponsiveContainer>
            ) : (
              <div className="flex items-center justify-center h-full text-[#96fdbf] text-lg">
                No points recorded yet
              </div>
            )}
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default PointsDisplay;
